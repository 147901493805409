<script context="module">
  import createTabsStore from "./createStore";

  const store = createTabsStore("my-tabs", { selected: "tab-1" });

  const tabs = [
    {
      text: "Tab 1",
      id: "tab-1"
    },
    {
      text: "Tab 2",
      id: "tab-2"
    }
  ];
</script>

<script>
  import Tabs from "./Tabs.svelte";

  $: selected = $store.selected;
</script>

<section class="tabs-demo">

  <Tabs name="my-tabs" {tabs} />

  {#if selected === 'tab-1'}
    <div class="content">Content of Tab 1</div>
  {/if}
  {#if selected === 'tab-2'}
    <div class="content">Content of Tab 2</div>
  {/if}
</section>

<style>
  :global(.tabs-demo ul) {
    display: flex;
    justify-content: center;
  }
  :global(.tabs-demo li) {
    margin-right: var(--gap-2);
  }
  .content {
    margin-top: var(--gap-2);
  }
</style>
