export const works = [
  {
    href: "#/target",
    title: "Target",
  },
  {
    href: "https://john-hong-studio.com",
    title: "John Hong Studio",
  },
  {
    href: "http://fadeinfilms.com",
    title: "Fade In Films",
  },
  // artjunk, cleverhire, oti
];

export const targetLinks = [
  {
    href: "https://www.fastcompany.com/90177570/target-has-a-secret-app-for-superfans-and-it-looks-like-instagram",
    text: "Target has a secret app for superfans, and it looks like Instagram",
    source: "fastcompany.com",
  },
  {
    href: "https://www.businessinsider.com/target-studio-connect-lets-shoppers-designers-collaborate2018-7?r=US&IR=T",
    text: "A new Target app connects customers to the design team",
    source: "businessinsider.com",
  },
  {
    href: "https://www.bustle.com/p/targets-app-studio-connect-invites-super-fans-of-the-store-to-collaborate-with-designers-9788159",
    text: `Target’s App “Studio Connect” Invites Super Fans Of The Store To
    Collaborate With Designers`,
    source: "bustle.com",
  },
];
export const projects = [
  {
    href: "https://kairos-weather.netlify.com/",
    // href: "https://kairos.now.sh/",
    title: "Kairos",
    descr: "find sunny destinations",
  },
  {
    href: "https://ontheinside.info/",
    title: "On The Inside",
    descr: "personality driven city guide",
  },
  {
    // href: "https://travel-info.online",
    href: "https://travel-info.netlify.com/",
    title: "Travel Info",
    descr: "easy travel searches",
  },
  // {
  //   href: "http://conceptor-app.com",
  // href: "http://conceptor.collardeau.com",
  //   title: "Conceptor",
  //   descr: "just a landing page"
  // },
  {
    // href: "https://life-on-bicycles.com",
    href: "https://life-on-bicycles.netlify.com",
    title: "Life On Bicycles",
    descr: "promotion bicycle culture",
  },
  {
    href: "https://book-vibes.com",
    title: "Book Vibes",
    descr: "preview a book in its own words",
  },
  // {
  //   href: "http://karaoke.collardeau.com/",
  //   txt: "Karaoke Machine",
  //   descr: "an old idea maybe worth revisiting"
  // }
  // tennis app
];

export const blogs = [
  {
    href: "https://dev.to/collardeau/props-up-with-svelte-4cjk",
    title: "Props up with Svelte",
    source: "dev.to",
  },
  {
    href: "https://dev.to/collardeau/lazy-loading-images-in-svelte-1mk6",
    title: "Lazy Loading Images in Svelte",
    source: "dev.to",
  },
  {
    href: "https://medium.com/@collardeau/a-committed-guide-to-reactjs-47c0371df3ab",
    title: "A Committed Intro Guide to ReactJS",
    source: "medium.com",
  },
  {
    href: "https://medium.com/@collardeau/intro-to-functional-programming-concepts-in-javascript-b0650773139c",
    title: "An Intro to Functional Programming Concepts",
    source: "medium.com",
  },
  // react-lil-router
];

export const social = {
  github: {
    href: "https://github.com/collardeau",
    handle: "collardeau",
    name: "github",
  },
  twitter: {
    href: "https://twitter.com/collardeau",
    handle: "@collardeau",
    name: "twitter",
  },
  devTo: {
    href: "https://dev.to/collardeau",
    handle: "collardeau",
    name: "dev.to",
  },
  vimeo: {
    href: "https://vimeo.com/collardeau",
    handle: "collardeau",
    name: "vimeo",
  },
  linkedIn: {
    href: "https://linkedin.com/in/collardeau",
    handle: "collardeau",
    name: "linkedin",
  },
  // stackoverflow, runkeeper, goodread
};

export const me = {
  tagline: "  ",
  intro: `<p>My name is Thomas Collardeau. I'm a <strong>Javascript developer</strong>
  and maker of web apps. I've used HTML, CSS and JavaScript for over 12 years,
  starting with usage of JQuery in my early days, then AngularJS (much to my chagrin) and embracing React in 2015(?). Currently, my favorite stack
  incude Sapper/Svelte and Firebase.</p><p>I'm originally from France and have lived in the US, The Netherlands, Spain and the United Kingdom.</p>In my free time, I love to travel to far away lands, paddle board on the open sea, and learn to play guitar.</p><p>In 2006, I was named TIME magazine's Person of the Year.</p>`,
  email: "tomadeau [at] gmail [dot] com",
  social,
};
